import React from 'react'
import { Link } from 'gatsby'

const OfferNav = () => {

  return (

    <nav className='offer-nav'>

      {/*<Link className='button-green ofnav' to='/offer/covid-19/'>
      <img width="10px" className='icon-green'  alt="covid-19" src='/img/viruses.svg'/>
        COVID-19</Link>
        <br />*/}
      <Link className='button-green ofnav' to='/offer/ecology/'>
      <img width="10px" className='icon-green'  alt="ecology" src='/img/leaf.svg'/>
        Ecology</Link>
      <br />
      <Link className='button-green ofnav' to='/offer/branding/' >
      <img width="10px" className='icon-green'  alt="branding" src='/img/pencil-ruler.svg'/>
        Branding</Link>
      <br />
      <Link className='button-green ofnav' to='/offer/graphic-design/' >
      <img width="10px" className='icon-green'  alt="graphic design" src='/img/pencil-alt.svg'/>
      Graphic design</Link>
      <br />
      <Link className='button-green ofnav' to='/offer/web-pages/' >
      <img width="10px" className='icon-green'  alt="Web pages" src='/img/code.svg'/>
        Web pages</Link>
      <br />
      <Link className='button-green ofnav' to='/offer/book-calendars/' >
      <img width="10px" className='icon-green'  alt="book calendars" src='/img/book.svg'/>
        Book Calendars</Link>
      <br />
      <Link className='button-green ofnav' to='/offer/company-calendars/' >
      <img width="10px" className='icon-green'  alt="business calendars" src='/img/calendar-alt.svg'/>
      Corporate calendars</Link>
      <br />
      <Link className='button-green ofnav' to='/offer/gadgets/' >
      <img width="10px" className='icon-green'  alt="gadgets" src='/img/lightbulb.svg'/>
      Promotional gadgets</Link>
      <br />
      <Link className='button-green ofnav' to='/offer/printouts/' >
      <img width="10px" className='icon-green'  alt="prints" src='/img/map.svg'/>
      Advertising prints</Link>
      <br />
      <Link className='button-green ofnav' to='/offer/outdoor-advertising/' >
      <img width="10px" className='icon-green'  alt="outdoor advertising" src='/img/object-ungroup.svg'/>
      Outdoor advertising</Link>
      <br />
      <Link className='button-green ofnav' to='/offer/photography/' >
      <img width="10px" className='icon-green'  alt="photography" src='/img/camera-retro.svg'/>
      Product photography</Link>
      <br />
      <Link className='button-green ofnav' to='/offer/exhibition/' >
      <img width="10px" className='icon-green'  alt="Exhibition" src='/img/cubes.svg'/>
      Exhibition</Link>
      <br />
      <Link className='button-green ofnav' to='/offer/filming/' >
      <img width="10px" className='icon-green'  alt="filming" src='/img/film.svg'/>
        Filming</Link>
      <br />
      <Link className='button-green ofnav' to='/offer/packaging/' >
      <img width="10px" className='icon-green'  alt="Packaging" src='/img/box-open.svg'/>
        Packaging</Link>
      <br />
      <Link  onLoad={ActiveBtn} className='button-green ofnav' to='/offer/vr/' >
      <img width="10px" className='icon-green'  alt="VR" src='/img/vr-cardboard.svg'/>
        VR</Link>

      <br />

    </nav>

  )
}

export default OfferNav

function ActiveBtn() {
  let i = 0;
  let title = document.getElementById('oftitle').innerHTML;
  console.log(title);


  for (i=0;i<document.getElementsByClassName('ofnav').length;i++){
    if(document.getElementsByClassName('ofnav')[i].innerHTML.match(title) !== null){

      console.log(document.getElementsByClassName('ofnav')[i]);
      document.getElementsByClassName('ofnav')[i].style.backgroundColor = '#00d1b2';
      document.getElementsByClassName('ofnav')[i].style.color = 'white';
      document.getElementsByClassName('ofnav')[i].firstChild.style.filter = 'brightness(0) invert(1)';


    }
  }
}
